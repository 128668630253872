<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <span class="heading">Report Details</span>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-card flat>
                      <v-layout wrap justify-center pa-2>
                        <v-flex xs12 sm12 lg12>
                          <!-- <v-layout wrap justify-start pt-10>
                              <v-flex xs12>
                                <span class="heading">Report Details</span>
                              </v-flex>
                            </v-layout> -->
                          <v-layout wrap justify-start>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="data && data.timeofincident"
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Time of Incident</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.timeofincident.slice(0, 10) }}
                                  <span v-if="!data.timeofincident">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="data && data.added_by && data.added_by.name"
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Added By</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.added_by.name }}
                                  <span v-if="!data.added_by.name">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start pt-10>
                            <v-flex xs12>
                              <span class="heading">Location</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs12>
                                  <div id="map"></div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start pt-10>
                            <v-flex xs12>
                              <span class="heading">Reply Now</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-start pt-2>
                                <v-flex xs12 md6 sm6 lg6 class="subheading">
                                  <span>Name</span>
                                  <v-autocomplete
                                    v-model="name"
                                    :items="snakelist"
                                    outlined
                                    dense
                                    item-text="name"
                                    item-value="_id"
                                  ></v-autocomplete>
                                </v-flex>
                                <v-flex
                                  xs12
                                  md6
                                  sm6
                                  lg6
                                  class="subheading"
                                  pl-lg-2
                                  pl-md-2
                                  pl-sm-2
                                >
                                  <span>Venom Type</span>
                                  <v-select
                                    v-model="venomType"
                                    :items="venomtypes"
                                    outlined
                                    dense
                                  ></v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start pt-2>
                                <v-flex xs12 md6 sm6 lg6 class="subheading">
                                  <span>Reply</span>
                                  <v-text-field
                                    outlined
                                    dense
                                    hide-details="true"
                                    v-model="reply"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex
                                  xs12
                                  md6
                                  sm6
                                  lg6
                                  class="subheading"
                                  pl-lg-2
                                  pl-md-2
                                  pl-sm-2
                                >
                                  <span>Description</span>
                                  <v-text-field
                                    outlined
                                    v-model="description"
                                    dense
                                    hide-details="true"
                                  >
                                  </v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pt-lg-5 pl-2>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm4 md4 lg4>
                    <v-btn depressed color="primary" @click="replyNow()">
                      <span>Reply</span>
                    </v-btn>
                  </v-flex>
                  <!-- <v-flex xs12 sm4 md4 lg4>
                      <v-btn
                        depressed
                        color="warning"
                        @click="approveDialog = true"
                      >
                        <span>Close Operation</span>
                      </v-btn>
                    </v-flex> -->
                  <!-- <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-sm-2 pl-md-2>
                      <v-btn depressed color="error" @click="rejectDialog = true">
                        <span>Delete Operation</span>
                      </v-btn>
                    </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="approveDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Close Operation</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span>Remarks</span>
                  <v-text-field outlined dense v-model="closeremarks">
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="approveDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="closeoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveoperationDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Approve Operation</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span>Remarks</span>
                  <v-text-field outlined dense v-model="approveremarks">
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="approveoperationDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="approveoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Approve</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text
          >Are you sure you want to delete the operation?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="rejectDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
    <script>
var marker;
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      data: [],
      reportedimages: [],
      rescuedimages: [],
      releasedimages: [],
      map: "",
      mapData: {},
      location: [],
      approveDialog: false,
      closeremarks: "",
      rejectDialog: false,
      divisionname: "",
      photoArray: [],
      approveremarks: "",
      idByAdmin: "",
      distance: "",
      approveoperationDialog: false,
      snakelist: [],
      venomtypes: ["Highly Venomous", "Mildly Venomous", "Non Venomous"],
      venomType: "",
      reply: "",
      name: "",
      description: "",
    };
  },
  beforeMount() {
    this.getData();
    this.getList();
  },
  //   mounted() {
  //   this.$nextTick(() => {
  //     this.initMap();
  //   });
  // },
  methods: {
    initMap() {
      var vm = this;
      var mapCanvas = document.getElementById("map");

      // Set default map options
      var mapOptions = {
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      };
      vm.map = new google.maps.Map(mapCanvas, mapOptions);

      if (vm.location && vm.location.length == 2) {
        console.log("Reported Latitude:", vm.location);

        // Create a shallow copy of the location to avoid reactive object issues
        const locationCopy = JSON.parse(JSON.stringify(vm.location));

        var reportedLat = locationCopy[0]; // Latitude of reported location
        var reportedLon = locationCopy[1]; // Longitude of reported location

        // Log latitude and longitude to confirm correct values
        // console.log("Reported Latitude:", reportedLat);
        // console.log("Reported Longitude:", reportedLon);

        mapOptions.center = new google.maps.LatLng(reportedLat, reportedLon);
        vm.map.setOptions(mapOptions);

        // Create a marker for the reported location
        var reportedMarker = new google.maps.Marker({
          map: vm.map,
          position: new google.maps.LatLng(reportedLat, reportedLon),
          title: "Reported Location",
        });

        // Create an InfoWindow for the reported location
        // var reportedInfoWindow = new google.maps.InfoWindow({
        //   content: "Reported Location",
        // });
        var reportedInfoWindow = new google.maps.InfoWindow({
          content: `
    <div style="width: 200px; height: 50px;">
      <h3 style="margin: 0;">Reported Location</h3>
    </div>
  `,
        });

        // Add a click event listener to the reported marker
        reportedMarker.addListener("click", function () {
          reportedInfoWindow.open(vm.map, reportedMarker);
        });
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/adminreply/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.data = response.data.data;
              // this.divisionname = this.data.division[0].divisionName;
              // this.reportedimages = response.data.reportedimages;
              // this.rescuedimages = response.data.rescuedimages;
              // this.releasedimages = response.data.releasedimages;
              this.location = this.data.where;
              // this.photoArray = this.data.photographs.map(
              //   (photoObj) => photoObj.photo
              // );
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.initMap();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/snake/all/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.snakelist = response.data.data;
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    replyNow() {
      if(!this.name){
        this.msg = "Please Provide Snake Name";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/adminreply/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          snake: this.name,
          venomtype: this.venomType,
          reply: this.reply,
          details: this.description,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Successfull.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.$router
                .push({
                  path: "/identificationhelp",
                })
                .then(() => {
                  window.location.reload();
                });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
#map {
  height: 300px !important;
  width: 100%;
}
</style>